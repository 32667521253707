<template>
  <ul class="topic">
    <li v-for="(item, index) in hots" :key="index" @click="detail(item)">
      <van-image lazy-load round :src="item.image || item.avatar" fit="cover">
        <template v-slot:default>
          <span class="vip-icon" v-if="item.is_vip == 1 && item.is_free == 1"
            >VIP</span
          >
          <!-- <span class="lessonCount"
            >{{ item.lessonCount }} {{ checkType(item.type) }}</span
          > -->
        </template>
      </van-image>
      <p class="cate_name">{{ item.title }}</p>
      <!-- <p class="price">
        <template
          v-if="
            (item.is_vip == 1 && item.is_free == 1 && isShowPrice) ||
            (item.is_vip == 2 && item.is_free == 1)
          "
        >
          <span>
            <i style="margin-left: 10px" class="icon-cny" />
            {{ item.price }}
          </span>
          <span class="oldPrice" v-if="item.old_price">
            <i style="margin-left: 6px" class="icon-cny" />
            {{ item.old_price}}
          </span>
          <div
            class="total_num views"
            style="direction: ltr; font-family: font-family: Arial, Helvetica, sans-serif; color: #999"
          >
            <van-icon style="margin-right: 4px" name="browsing-history-o" />
            <span class="time">{{ item.views }}</span>
          </div>
          <div class="total_num" v-if="item.orderCount">
            {{ item.orderCount }} ادام ساتىپ الدى
          </div>
        </template>
      </p> -->
    </li>
  </ul>
</template>

<script>
import { getItem } from '@/utils/storage'
export default {
  props: {
    hots: {
      type: Array
    },
    clickType: {
      type: String,
      default: 'course'
    }
  },
  data () {
    return {
      isShowPrice: ''
    }
  },

  created () {
    this.isShowPrice = !getItem('vipStatus')
  },

  filters: {
    formatPrice (price, count) {
      let l = 0
      let newPrice = parseInt(price)
      while (newPrice >= 1) {
        newPrice = newPrice / 10
        l++
      }

      if (l >= 3 && count > 0) {
        return parseInt(price)
      } else {
        return price
      }
    }
  },

  methods: {
    detail ({ id, type }) {
      let path = ''
      if (this.clickType === 'course') {
        path = { name: 'detail', params: { parent_id: id, type } }
      } else {
        path = {
          path: '/topic',
          query: {
            id
          }
        }
      }
      this.$router.push(path)
      document.documentElement.scrollTop = 0
    },
    checkType (type) {
      let name = ''
      switch (type) {
        case 'article':
          name = 'ماقالا'
          break
        case 'audio':
          name = 'اۋديو'
          break
        case 'video':
          name = 'ۆيديو'
          break
      }
      return name
    }
  }
}
</script>

<style lang="less" scoped>
// 专题
.topic {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  background-color: #fff;
  direction: rtl;
  li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 0;
    text-align: center;
    /deep/ .van-image {
      width: 94%;
      height: 200px;
      border-radius: 6px !important;
      object-fit: cover;
      margin-bottom: 14px;
      vertical-align: top;
    }

    .cate_name {
      flex: 1;
      font-size: 26px;
      text-align: center;
      padding: 0 20px;
      color: #3a3a3a;
      margin-bottom: 4px;
    }

    .price {
      // margin-right: auto;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
      direction: ltr;
      margin-bottom: 14px;
      > span {
        font-size: 28px;
        color: #ee0a24;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif;
      }

      .oldPrice {
        text-decoration: line-through;
        font-size: 20px;
        color: #999;
        font-weight: normal;
      }

      .total_num {
        direction: rtl;
        color: #ee0a24;
        // height: 38px;
        font-size: 20px;
        padding: 0 15px 0 0;
        margin-left: auto;
      }
    }

    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
