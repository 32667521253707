<template>
  <div class="home-container">
    <!-- 加载中 -->
    <div v-if="loading" class="loading-wrap">
      <van-loading color="#3296fa" vertical>مازمۇن ىزدەلۋدە...</van-loading>
    </div>
    <template v-if="!loading">
      <div class="bg-white pdt-20">
        <!-- 搜索区域 -->
        <div class="search" @click="$router.push({ path: '/search' })">
          <van-icon name="search" />
          <span>ىزدەيتىن مازمۇندى جازىڭىز</span>
        </div>

        <!-- 焦点图 -->
        <van-swipe class="my-swipe" indicator-color="white" :autoplay="3000">
          <van-swipe-item
            v-for="(item, index) in swiper"
            :key="index"
            @click="onTapToDetail(item.course)"
          >
            <van-image fit="cover" lazy-load :src="item.image" />
          </van-swipe-item>
        </van-swipe>

        <van-notice-bar
          class="rtl notice"
          color="#ed1c24"
          background="#ecf9ff"
          right-icon="info-o"
          wrapable
        >
          ەلىمىزدىڭ زاڭ-ساياساتتارىنا بويسىنىپ، ينتەرنەتتەن مادەنيەتتى
          پايدالانايىق. ەگەردە سايتىمىزدان ءارقانداي زاڭعا قيعاش كەلگەن نەمەسە
          جەكە ادام مۇددەسىنە قايشى مازمۇنداردى بايقاساڭىز سايت جاۋاپتىسىمەن
          قابارلاسىڭىزدار. تيەسەلى سيلىق بەرىلەدى. قيقاش كەلگەن مازمۇندار 24
          ساعات ىشىندە وشىرىلەدى.
        </van-notice-bar>
      </div>

      <!-- 分类模块 -->
      <Category :cates="cates" />

      <van-notice-bar
        class="rtl notice"
        wrapable
        left-icon="volume-o"
        :scrollable="false"
        text="VIP مۇشە بولساڭىز باعدارلاماداعى بارلىق مازمۇندى تەگىن كورۋىڭىزگە بولادى. جوعارىداعى VIP دەگەندى باسىپ مۇشە بولىڭىز."
      />

      <!-- <van-notice-bar
        class="rtl notice"
        color="#ed1c24"
        background="#ecf9ff"
        right-icon="info-o"
        wrapable
      >
        3 ايدان ارتىق مۇشە بولعاندارعا سيلىق بەرىلەدى. قيمىل ۋاقىتى شەكتى.
        <van-button type="danger" to="/vip">تەزدەن مۇشە بولىڭىز</van-button>
      </van-notice-bar> -->

      <div class="content" v-if="hots.length || topicList.length">
        <template v-if="hots.length">
          <!-- 推荐模块 -->
          <div class="hd-title">
            <span class="name">تانىستىرۋ</span>
          </div>
          <GridItem :hots="hots" />
        </template>

        <template v-if="teachers.length">
          <div class="hd-title mt-10">
            <span class="name">جەكە پاراقشالار</span>
            <span class="more" @click="$router.push({ path: '/teacherList' })"
              >تولعىراق<van-icon name="arrow-left"
            /></span>
          </div>
          <Teacher :teachers="teachers" />
        </template>

        <!-- 专题模块 -->
        <template v-if="topicList.length">
          <div class="hd-title mt-10">
            <span class="name">ارناۋلى ايدارشا</span>
            <span class="more" @click="$router.push({ path: '/topicList' })"
              >تولعىراق<van-icon name="arrow-left"
            /></span>
          </div>
          <BigCourseItem :hots="topicList" clickType="topic" />
        </template>
      </div>

      <!-- 按分类显示课程 -->
      <template v-for="item in allData">
        <div class="content" :key="item.title" v-if="item.course.length">
          <div class="hd-title">
            <span class="name">{{ item.name }}</span>
            <span
              class="more"
              @click="$router.push({ path: '/grid', query: { id: item.id } })"
              >تولعىراق<van-icon name="arrow-left"
            /></span>
          </div>

          <GridItem v-if="item.display == 2" :hots="item.course" />
          <ThreeGrid v-else-if="item.display == 3" :hots="item.course" />
          <CourseItem v-else :hots="item.course" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Teacher from '@/components/Teacher'
import GridItem from '@/components/Grid-item'
import CourseItem from '@/components/Course-item'
import ThreeGrid from '@/components/Three-Grid'
import BigCourseItem from '@/components/BigCourseItem'
import Category from '@/components/Category'
import wx from 'weixin-js-sdk'
import { initShareInfo } from '@/utils/util'
import {
  getAllSwiper,
  getAllCategory,
  getRecomList,
  getCoursesByCateList
} from '@/api/home'
import { getAllTopic } from '@/api/topic'
import { getAllTeacher } from '@/api/Teaher'
export default {
  name: 'HomeIndex',
  components: {
    GridItem,
    CourseItem,
    Category,
    BigCourseItem,
    ThreeGrid,
    Teacher
  },
  data () {
    return {
      loading: true,
      // 搜索关键词
      value: '',
      // 焦点图
      swiper: [],
      // 分类
      cates: [],
      // hots
      hots: [],
      allData: [],
      topicList: [], // 专题列表
      pages: {
        pagenum: 1,
        pagesize: 4,
        total: null
      },
      active: 0,
      teachers: [], // 教师列表
      catetemplist: [
        // {
        //   name: 'اۆتور',
        //   icon: 'https://mmbiz.qpic.cn/mmbiz_png/iapicg9gRmw7rDXdofrBezL2CHzb6NvrWibKAvYw2ETFeT9iczOzd2nV7pvgOFnJ5FPiaia69crBlHpB91gmL9vg2o0Q/0?wx_fmt=png'
        // },
        // {
        //   name: 'جەكە پاراقشا',
        //   icon: 'https://mmbiz.qpic.cn/mmbiz_png/iapicg9gRmw7rDXdofrBezL2CHzb6NvrWibKAvYw2ETFeT9iczOzd2nV7pvgOFnJ5FPiaia69crBlHpB91gmL9vg2o0Q/0?wx_fmt=png'
        // }
      ]
    }
  },
  mounted () {
    document.title = 'الاتاۋ كەسكىن ارناسى'
    this.getAllSwiper()
    this.getAllCategory()
    this.getRecomList()
    this.gettAllData()
    this.getAllTopic()
    this.getAllTeacher()
    wx.ready(() => {
      initShareInfo({
        title: 'الاتاۋ كەسكىن ارناسى', // 分享标题
        desc: 'ەڭ كەمەلدى قازاقشا كەسكىن كورۋ ارناسى', // 分享描述
        imgUrl: 'https://dombira.otirar.com/static/img/Ui1.2a17650.png' // 分享图标
      })
    })
  },

  methods: {
    async getAllSwiper () {
      try {
        const res = await getAllSwiper({
          params: {
            pagenum: this.pages.pagenum,
            pagesize: this.pages.pagesize
          }
        })

        if (res.code === 200 && res.errorCode === 0) {
          this.swiper = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getAllTeacher () {
      try {
        const res = await getAllTeacher({
          pagenum: this.pages.pagenum,
          pagesize: 10,
          // is_teacher: '1',
          random: 'Y'
        })

        if (res.code === 200 && res.errorCode === 0) {
          this.teachers = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getAllCategory () {
      try {
        const res = await getAllCategory()
        if (res.code === 200 && res.errorCode === 0) { this.cates = [...res.data.data, ...this.catetemplist] }
      } catch (error) {
        console.log(error)
      }
    },

    async getRecomList () {
      try {
        const res = await getRecomList({ recommend: 2, pagesize: 8 })
        if (res.code === 200 && res.errorCode === 0) this.hots = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async getAllTopic () {
      try {
        const res = await getAllTopic({ pagesize: 4, random: 'Y' })
        if (res.code === 200 && res.errorCode === 0) {
          this.topicList = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },

    async gettAllData () {
      try {
        this.loading = true
        const res = await getCoursesByCateList()
        if (res.code === 200 && res.errorCode === 0) {
          this.allData = res.data.data
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    onTapToDetail (item) {
      this.$router.push({
        name: 'detail',
        params: { parent_id: item.id, type: item.type }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  // padding-top: 92px;
  padding-bottom: 100px;

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f3f5;
    color: #999;
    border-radius: 30px;
    font-size: 26px;
    direction: rtl;
    margin: 0 auto 20px;

    span {
      margin-right: 10px;
    }
  }

  .my-swipe {
    height: 300px;
    margin: 20px;
    margin-bottom: 0;
    border-radius: 10px;
    background-color: #fff;
    .van-swipe-item {
      /deep/ .van-image {
        height: 300px;
      }
    }
  }

  .content {
    margin: 10px 0;
    direction: rtl;
  }
}
</style>
